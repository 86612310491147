
import Vue from "vue";
import Logo from "@/components/common/Logo.vue";

export default Vue.extend({
  name: "SignupTermsAndConditions",
  components: { Logo },
  data() {
    return {
      image: require("/src/assets/Vertical-Travel-quarter-container.jpg"),
    };
  },
  computed: {
    terms: function (): string {
      return this.$store.state.terms;
    },
  },
  methods: {},
});
